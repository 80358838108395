import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faLocationDot,
  faPaperPlane,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import "../styles/scss/footer.scss";
import footerImage2 from "../assets/images/image_group_footer_2.svg";
import emailIcon from "../assets/images/email_icon_footer.png";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export const Footer = () => {
  const [email, setEmail] = useState("");

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };
  const emailUrl = process.env.REACT_APP_EMAIL_SERVICE;
  const handleSubscribe = async (e) => {
    try {
      const response = await axios.post(emailUrl, {
        from: "support@qpaix.com",
        to: email,
        subject: "Subscription Confirmation",
        body: `Hello Dear, 
       <p> Thank you for subscribing to our newsletter! You will now receive updates and news from us.</p>
          <p> Best Regards,\n</p>
         <p><b> Visves Advance Engineering.</b></p>`,
      });
      if (response.data.code === 200) {
        toast.success("Subscription Successful");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Subscription Failed");
    }
  };

  return (
    <React.Fragment>
      <footer className="footer text-white" style={{ width: "100%" }}>
        <div className="container">
          <div className="row card_section align-items-center">
            <div className="col-lg-6">
              <div className="d-flex align-items-center mb-3 mb-lg-0">
                <img
                  src={emailIcon}
                  alt="Icon"
                  className="img-fluid img_media"
                />
                <h1 className="ms-3 pt-3">Stay Ahead with Visves</h1>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control input_box"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleInputChange}
                />
                <button
                  type="button"
                  className="btn btn-light btn-lg rounded button_function"
                  onClick={handleSubscribe}
                >
                  Subscribe
                  <FontAwesomeIcon
                    icon={faPaperPlane}
                    style={{ color: "#05092b" }}
                    className="icon ps-2"
                  />
                </button>
              </div>
            </div>
          </div>

          <div>
            <div className="row mx-auto">
              <div className="col-lg-4 col-md-12">
                <div className="footer__content">
                  <Link to="/">
                    <img
                      src={footerImage2}
                      alt="Logo"
                      className="footer__logo img-fluid"
                    />
                  </Link>

                  <p className="footer__desc">
                    We design and engineer custom-automated control solutions
                    that reduce your costs and give your business the
                    competitive edge it needs to succeed.
                  </p>

                  <div className="d-flex ">
                    <Link
                      to="https://www.facebook.com/Visvesadvanceengineering?mibextid=ZbWKwL"
                      className="text-decoration-none"
                    >
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        style={{ color: "#f7f7f8" }}
                        className="me-3"
                      />
                    </Link>

                    <Link
                      to="https://in.linkedin.com/company/visvesadvanceengineering"
                      className="text-decoration-none"
                    >
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        style={{ color: "#f7f7f8" }}
                        className="me-3"
                      />
                    </Link>

                    <Link
                      to="https://www.instagram.com/visvesadvance/"
                      className="text-decoration-none"
                    >
                      <FontAwesomeIcon
                        icon={faInstagram}
                        style={{ color: "#f7f7f8" }}
                        className="me-3"
                      />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6">
                <div className="footer__content">
                  <h3 className="footer__title">USEFUL LINKS</h3>

                  <ul className="footer__links list-unstyled">
                    <li>
                      <Link class="nav-link link-light" to="/">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Home
                      </Link>
                    </li>

                    <li>
                      <Link className="nav-link link-light" to="/about">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        About Us
                      </Link>
                    </li>

                    <li>
                      <Link className="nav-link link-light" to="/products">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Products
                      </Link>
                    </li>

                    <li>
                      <Link className="nav-link link-light" to="/services">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Services
                      </Link>
                    </li>

                    <li>
                      <Link className="nav-link link-light" to="/blog">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Blogs
                      </Link>
                    </li>

                    <li>
                      <Link className="nav-link link-light" to="/contact-us">
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-2 col-md-6">
                <div className="footer__content">
                  <h3 className="footer__title">PRODUCTS</h3>

                  <ul className="footer__links footer-product-link list-unstyled">
                    <li>
                      <Link
                        class="nav-link link-light"
                        to="/products/door-smart-rtu"
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        3 Door Smart RTU Automated Control Panel
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="nav-link link-light"
                        to="/products/actuator-valve-control-panel"
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Actuator Valve Control Panel
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="nav-link link-light"
                        to="/products/mini-scada-control-panel"
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Mini SCADA Panel for Pump Operation and ESR Tank Level
                        Management
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="nav-link link-light"
                        to="/products/smart-integrated-pump"
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Smart Integrated Pump and Valve Automated control Panel
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="nav-link link-light"
                        to="/products/advanced-plc-rtu"
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        Advanced PLC RTU DI-DO-AI-AO Module
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="nav-link link-light"
                        to="/products/vae-iot-rtu"
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          style={{ color: "#351ee0" }}
                          className="me-2"
                        />
                        VAE RTU IoT
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="footer__content">
                  <h3 className="footer__title">CONTACT US</h3>

                  <ul className="footer__links list-unstyled">
                    <li class="d-flex">
                      <Link to="#" className="text-decoration-none">
                        <FontAwesomeIcon
                          icon={faLocationDot}
                          style={{ color: "#351ee0" }}
                          className="me-2 mt-1"
                        />
                      </Link>
                      <Link
                        className="nav-link link-light"
                        to="https://maps.app.goo.gl/Miyqh4sXFDhyFk5r8"
                      >
                        G-39, Safal Sumel Business Park-6, Nr. HanumanPura BRTS
                        Bus Stand, Dudheshawar, Ahmedabad. 380004. (Guj.) India.
                      </Link>
                    </li>

                    <li className="d-flex contact_media">
                      <FontAwesomeIcon
                        icon={faPhone}
                        style={{ color: "#351ee0" }}
                        className="me-2 mt-1"
                      />

                      <div className="d-flex gap-3 flex-wrap">
                        <Link
                          className="nav-link link-light"
                          to="tel:9664980829"
                        >
                          +91 9664980829
                        </Link>
                      </div>
                    </li>

                    <li className="d-flex contact_media">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ color: "#351ee0" }}
                        className="me-2 mt-1"
                      />

                      <div className="d-flex column-gap-3 row-gap-1 flex-wrap">
                        <Link
                          className="nav-link link-light"
                          to="mailto: purchase@visves.com"
                        >
                          purchase@visves.com
                        </Link>

                        <Link
                          className="nav-link link-light"
                          to="mailto:info@visves.com"
                        >
                          info@visves.com
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <hr className="container line"></hr>

          <div className="row footer-end mb-2">
            <p className="col-lg-8 mb-3 mb-lg-0 text-center text-lg-start">
              <Link
                to="/"
                className="text-white"
                style={{ textDecoration: "none" }}
              >
                {" "}
                © Visves Advance Engineering
              </Link>{" "}
              - 2024. All rights reserved. | Designed and Developed by Qpaix
              Infitech Pvt.Ltd.
            </p>

            <ul className="col-lg-4 list-unstyled d-block d-md-flex flex-row-reverse mb-0 justify-content-center justify-content-lg-start">
              <li>
                <Link
                  className="nav-link link-light px-2 text-center text-md-start"
                  to="/contact-us"
                >
                  Contact Us
                </Link>
              </li>

              {/* <li>
                <Link
                  className="nav-link link-light px-2 text-center text-md-start"
                  to="under-maintenance"
                >
                  Privacy Policy
                </Link>
              </li> */}

              {/* <li>
                <Link className="nav-link link-light px-2 text-center text-md-start" to="#">
                  Terms & Condition
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

import React from "react";
import director from "../../assets/images/aboutusImages/Director-image.jpg";
import profile from "../../assets/images/leadership/profile.jpg";
import vaibhav from "../../assets/images/leadership/Teams/VAIBHAV  PATEL.jpg";
import ajay from "../../assets/images/leadership/Teams/AJAY  SOLANKI.jpg";
import aniket from "../../assets/images/leadership/Teams/ANIKET  PANCHAL.jpg";
import chetan from "../../assets/images/leadership/Teams/CHETAN  RAVAT.jpg";
import dhaval from "../../assets/images/leadership/Teams/DHAVAL  PRAJAPATI.jpg";
import hitesh from "../../assets/images/leadership/Teams/HITESH  PANCHAL.jpg";
import jaimin from "../../assets/images/leadership/Teams/JAIMIN PARMAR.jpg";
import meet from "../../assets/images/leadership/Teams/MEET PATEL.jpg";
import pranali from "../../assets/images/leadership/Teams/PRANALI  TRIVEDI.jpg";
import prakash from "../../assets/images/leadership/Teams/PRAKASH  DETRUJYA.jpg";
import ronak from "../../assets/images/leadership/Teams/RONAK  RAVAT.jpg";
import sachin from "../../assets/images/leadership/Teams/SACHIN PATNI.jpg";
import santosh from "../../assets/images/leadership/Teams/SANTOSH  DETRUJIYA.jpg";
import sundar from "../../assets/images/leadership/Teams/SUNDER  SOLANKI.jpg";
import tirth from "../../assets/images/leadership/Teams/tirth malvania.jpg";
import vishwash from "../../assets/images/leadership/Teams/VISHWASH  BHAVSAR.jpg";

export const Teams = () => {
  return (
    <React.Fragment>
      <div className="teams-section py-5">
        <div className="container">
          <div className="text-center mb-5">
            <h3 class="fs-2 underline underline-heading texts text-justify">
              Founders
            </h3>
          </div>
          <div className="row founders-team-row mb-5 pb-5 border-bottom">
            <div className="col-lg-4 col-md-6">
              <div className="card team-card">
                {/* <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={profile}
                  alt="Card image cap"
                /> */}
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. VIKAS TRIVEDI
                  </h5>
                  <p className="card-text team-card-text">
                    M.D (Industrial Expansion and Financial Affairs)
                  </p>

                  <p className="card-text team-card-text">
                    Email ID:- vikas@visves.com
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card">
                {/* <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={director}
                  alt="Card image cap"
                /> */}
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. AKASHKUMAR BHATTI
                  </h5>
                  <p className="card-text team-card-text">
                    DIRECTOR (Pre-sales & Production)
                  </p>

                  <p className="card-text team-card-text">
                    Email ID:- ab@visves.com
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card">
                {/* <img
                  className="card-img-top img-fluid team-card-img-top"
                  src={profile}
                  alt="Card image cap"
                /> */}
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. BHIKHABHAI PANCHAL
                  </h5>
                  <p className="card-text team-card-text">DIRECTOR</p>

                  {/* <p className="card-text team-card-text">
                    Email ID:- trivadi@visves.com
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-5">
            <h3 class="fs-2 underline underline-heading texts text-justify">
              Advisory Board of VISVES
            </h3>
          </div>
          <div className="row founders-team-row mb-5 pb-5 border-bottom">
            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    Dr. VINAY DEEP PUNETHA
                  </h5>
                  <p className="card-text team-card-text">
                    PhD, Postdoctoral: south Korea
                  </p>
                  {/* <p className="card-text team-card-text">Number:- 8000301086</p>
                  <p className="card-text team-card-text">
                    Email ID:- ab@visves.com
                  </p> */}
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">MR. PARTH MODI</h5>
                  <p className="card-text team-card-text">
                    Technical head Education Department
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. Malhar Pandya
                  </h5>
                  <p className="card-text team-card-text">Project Analyst</p>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mb-5">
            <h3 class="fs-2 underline underline-heading texts text-justify">
              Our Research and Development Team
            </h3>
          </div>
          <div className="row founders-team-row mb-5 pb-5 border-bottom">
            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    MR. CHIRAG PANCHAL
                  </h5>
                  <p className="card-text team-card-text">
                    Research and Development HEAD
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">AKSHAY SANKLE</h5>
                  <p className="card-text team-card-text">
                    Sr. R&D Project Engineer
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">VAIBHAV PATEL</h5>
                  <p className="card-text team-card-text">
                    R & D PROJECT ENGINEER
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">MEET PATEL</h5>
                  <p className="card-text team-card-text">
                    R & D PROJECT ENGINEER
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    PRANALI TRIVEDI
                  </h5>
                  <p className="card-text team-card-text">
                    R & D PROJECT ENGINEER
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    DHAVAL PRAJAPATI
                  </h5>
                  <p className="card-text team-card-text">
                    R & D PROJECT ENGINEER
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">HARSH TRIVEDI</h5>
                  <p className="card-text team-card-text">
                    R & D PROJECT ENGINEER
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mb-5">
            <h3 class="fs-2 underline underline-heading texts text-justify">
              Our Team
            </h3>
          </div>
          <div className="row founders-team-row">
            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    PRATHAM MAKWANA
                  </h5>
                  <p className="card-text team-card-text">
                    OPERATIONAL INCHARGE/ SR.PRODUCTION ENGINEER
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">MAITRI BHAVSAR</h5>
                  <p className="card-text team-card-text">
                    ASSISTANT ADMINISTRATIVE
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">TIRTH MALVANIA</h5>
                  <p className="card-text team-card-text">PROJECT ENGINEER</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">JAIMIN PARMAR</h5>
                  <p className="card-text team-card-text">PROJECT ENGINEER</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">ANIKET PANCHAL</h5>
                  <p className="card-text team-card-text">PROJECT ENGINEER</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">VIDHI BHISIKAR</h5>
                  <p className="card-text team-card-text">
                    JR. PROJECT ENGINEER
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">SMRUTI RAJ</h5>
                  <p className="card-text team-card-text">
                    JR. PROJECT ENGINEER
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">
                    HASMUKH PANCHAL
                  </h5>
                  <p className="card-text team-card-text">SR. TECHNICIAN</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">RONAK RAVAT</h5>
                  <p className="card-text team-card-text">TECHNICIAN</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">CHETAN RAVAT</h5>
                  <p className="card-text team-card-text">TECHNICIAN</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">AJAY SOLANKI</h5>
                  <p className="card-text team-card-text">TECHNICIAN</p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="card team-card advisory-card">
                <div className="card-body team-card-body">
                  <h5 className="card-title team-card-title">SUNDAR SOLANKI</h5>
                  <p className="card-text team-card-text">TECHNICIAN</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
